.main-show{
    background-color: rgb(250, 250, 250);
    height: 100vh;
    width: 100vw;
    position: absolute;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.main-hide{
    visibility: collapse;

    background-color: rgb(250, 250, 250);
    height: 100vh;
    width: 100vw;
    position: absolute;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.div{
    height: 125px;
    width: 125px;

    display: grid;
    grid-template-columns: 100px 25px;
}

.txt{
    color: #2c2c2c;
    text-align: center;
    margin: 15px 0px 0px 16px;
}

.gif{
    max-height: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}